import {Cluster, DefaultRenderer} from "@googlemaps/markerclusterer";
import {GoogleMapsFactory} from "./googleMapsFactory";
import {resolve} from "../../container";
import {elementFrom} from "../../common/utils/html";

export class EopCustomClusterRenderer extends DefaultRenderer {
    private googleMapsFactory: GoogleMapsFactory = resolve(GoogleMapsFactory);

    public constructor(private strokeColor: string, private fillColor: string) {
        super();
    }

    public render(cluster: Cluster): google.maps.marker.AdvancedMarkerElement {
        return this.googleMapsFactory.createMarker({
            position: cluster.position,
            content: this.circleIcon(String(cluster.count)),
            zIndex: 1000 + cluster.count
        });
    }

    private circleIcon(label: string): Element {
        const width = 60;
        const height = 60;
        const svg = `
            <svg xmlns="http://www.w3.org/2000/svg" 
                width="60px" height="${height}px" cursor="pointer" viewBox="0 0 ${width} ${height}"
                fill="${this.fillColor}"
                fill-opacity="1"
                fill-rule="evenodd"
                stroke="${this.strokeColor}" 
                stroke-width="2"
                stroke-opacity="1"
                text-anchor="start"
             >
                <circle cx="${width / 2}" cy="${height / 2}" r="26" />
                <text fill="${this.strokeColor}" 
                font-weight="200" font-size="1.2em" x="${width / 2}" y="${(height / 2) + 5}" text-anchor="middle" stroke-width="1">${label}</text>
             </svg>
            `;
        return elementFrom(svg);
    }
}