import {autoRegister, resolve} from "../../container";
import {Deferred} from "../../common/utils/promises";
import {Configuration} from "../../common/config";
import {Load} from "../../common/load";
import {GLOBAL} from "../../common/globals";
import {UrlBuilder} from "../../common/utils/url";

export type GoogleMapsWindow = Window & {
    onGoogleMapsReady: () => void;
};

@autoRegister()
export class GoogleMapsRunner {
    private readonly GOOGLEMAPS_URL: string | undefined;
    private readonly GOOGLEMAPS_CLIENTID: string | undefined;
    private clientDeferred: Deferred<void>;
    private isLoaded: boolean;
    private window: GoogleMapsWindow;

    public constructor(
        private config: Configuration = resolve(Configuration),
        private load: Load = resolve(Load)
    ) {
        this.clientDeferred = new Deferred<void>();
        this.GOOGLEMAPS_URL = this.config.get("GOOGLEMAPS_URL");
        this.GOOGLEMAPS_CLIENTID = this.config.get("GOOGLEMAPS_CLIENTID");
        this.isLoaded = false;
        this.window = GLOBAL.window() as GoogleMapsWindow;
    }

    public run(): Promise<void> {
        if (this.isLoaded) {
            return this.clientDeferred.promise;
        }
        if (this.GOOGLEMAPS_URL && this.GOOGLEMAPS_CLIENTID) {
            this.isLoaded = true;
            this.window.onGoogleMapsReady = () => {
                this.clientDeferred.resolve();
            };
            const apiUrl = new UrlBuilder(this.GOOGLEMAPS_URL)
                .withQueryParameter("client", this.GOOGLEMAPS_CLIENTID)
                .withQueryParameter("callback", "onGoogleMapsReady")
                .withQueryParameter("loading", "async")
                .withQueryParameter("libraries", "marker")
                .build();

            void this.load.script(apiUrl);
        } else {
            this.clientDeferred.reject();
        }

        return this.clientDeferred.promise;
    }
}