import {autoRegister} from "../../container";
import {DefaultRenderer, MarkerClusterer, SuperClusterAlgorithm} from "@googlemaps/markerclusterer";

@autoRegister()
export class GoogleMapsFactory {

    public constructor(
        private markerClusterer: any = MarkerClusterer,
        private clusterAlgorithm: any = SuperClusterAlgorithm) {
    }

    public createMap(element: HTMLElement, options: google.maps.MapOptions): google.maps.Map {
        return new google.maps.Map(element, options);
    }

    public createMarker(options: google.maps.marker.AdvancedMarkerElementOptions): google.maps.marker.AdvancedMarkerElement {
        return new google.maps.marker.AdvancedMarkerElement(options);
    }

    public createMarkerClusterer(map: google.maps.Map, markers: google.maps.marker.AdvancedMarkerElement[], renderer: DefaultRenderer): MarkerClusterer {
        return new this.markerClusterer({
            map: map,
            markers: markers,
            algorithm: new this.clusterAlgorithm({radius: 200}),
            renderer: renderer
        });
    }

    public createLatLng(lat: number, lng: number): google.maps.LatLng {
        return new google.maps.LatLng({
            lat: lat,
            lng: lng
        });
    }

    public createPoint(x: number, y: number): google.maps.Point {
        return new google.maps.Point(x, y);
    }
}